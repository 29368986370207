import React from 'react'
import Link from 'gatsby-link'

import certificationPlumberFull from '../img/certificationPlumberFull.png'
import certificationDrainlayerFull from '../img/certificationDrainlayerFull.png'
import certificationGasfitterFull from '../img/certificationGasfitterFull.png'

import Layout from '../components/layout'

const Services = () => (
  <Layout>
    <div className="box">
      <h2 className="stripe">Our Services</h2>
      <Link to="/services/plumbing">
        <img src={certificationPlumberFull} height="250" width="160" alt="Certifying Plumber"
             style={{ margin: '0 75px' }}/>
      </Link>
      <Link to="/services/drainlaying">
        <img src={certificationDrainlayerFull} height="250" width="160" alt="Certifying Drainlayer"
             style={{ margin: '0 75px' }}/>
      </Link>
      <Link to="/services/gasfitting">
        <img src={certificationGasfitterFull} height="250" width="160" alt="Certifying Gasfitter"
             style={{ margin: '0 75px' }}/>
      </Link>
      <br className="clear"/>
    </div>
  </Layout>
)

export default Services
